@import "./colors.scss";
$pn-font-path: "../fonts" !default;

@font-face {
  font-family: "Montserrat";
  src: url("#{$pn-font-path}/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Montserrat" !important;
}

.jin--title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 2.5vw;
  line-height: 7vh;
  color: $grey;
}

.jin--title-layout {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 1vh;
  color: $orange;
}

.jin--subtitle-layout {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1vw;
  line-height: 0.5vh;
  color: $grey;
}

.jin--form-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 2vh;
  color: $orange;
}

.jin--table-header {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 2vh;
  color: $orange;
}

.jin--table-td {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 2vh;
  color: $grey;
}
.ant-pagination .ant-pagination-item-active a {
  color: $orange;
}
.ant-pagination .ant-pagination-item-active {
  border-color: $orangeLight;
}

.jin--text {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vh;
  color: $orangeLight;
}

.jin--logo-container {
  font-family: "Montserrat";
  height: 11vh;
  background: $orange;
  align-items: center;
  text-align: center;
}

.jin--logo-menu-left {
  font-family: "Montserrat";
  text-align: center;
  margin: 1vh;
  height: 8vh !important;
  width: 11vh !important;
}

.ant-layout-sider {
  width: 18vw !important;
  min-width: 18vw !important;
  background-color: $lightGrey !important;
}

.ant-menu-dark {
  background-color: $lightGrey !important;
}

.jin--admin-container {
  margin-top: 1vh;
  text-align: center;
}

.ant-menu-item {
  font-family: "Montserrat" !important;

  font-weight: 700 !important;
  line-height: 3.5vh !important;
  font-size: 1.2vw !important;
  color: $orange !important;
  height: 3.5vh !important;
  margin-top: 2vh !important;
  padding-left: 2vh !important;
}

.ant-menu-item:before {
  font-family: "Montserrat";
  content: "";
  position: absolute;
  width: 6.5vh;
  height: 1px;
  bottom: 0;
  left: 2vh;
  border-bottom: 2px solid $orange;
}

.jin--text-admin {
  font-family: "Montserrat";
  font-weight: 700 !important;
  line-height: 3.5vh !important;
  font-size: 1.2vw !important;
  color: $orange !important;
  height: 3.5vh !important;
  margin-top: 2vh !important;
}

.ant-menu-item-selected {
  height: 4.6vh !important;
  background-color: $orange !important;
  color: #fff !important;
}

.ant-menu-item-selected:before {
  font-family: "Montserrat";
  content: "";
  position: absolute;
  width: 6.5vh;
  height: 1px;
  bottom: 3px;
  left: 2vh;
  border-bottom: 2px solid #fff;
}

.ant-layout-header {
  font-family: "Montserrat";
  height: 11vh !important;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
}

.jin--input {
  font-family: "Montserrat";
  width: 90% !important;
  height: 5vh !important;
  border-radius: 35px !important;
  border: 3px solid $orangeLight !important;
  color: $orange !important;
  font-weight: 400 !important;
  font-size: 1.5vh !important;
  line-height: 3.5vh !important;
}
.jin--input-texterea {
  font-family: "Montserrat";
  width: 90% !important;
  border-radius: 35px !important;
  border: 3px solid $orangeLight !important;
  color: $orange !important;
  font-weight: 400 !important;
  font-size: 1.5vh !important;
  line-height: 3.5vh !important;
  min-height: 50vh;
}

.jin--form-login {
  background-color: "white";
  margin-top: -5% !important;
  box-shadow: 20px 20px 30px 30px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 10px 10px 30px 30px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 10px 10px 15px 30px rgba(0, 0, 0, 0.05);
  //border: 1px solid red;
  padding: 3vh;
  min-width: 30vw;
}
.jin--checklist-text {
  font-family: "Montserrat";
  font-weight: 500;
  //font-size: 14px;
}
.jin--form-item-login {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.jin--btn {
  font-family: "Montserrat";
  width: 20vw !important;
  min-width: 200px !important;
  height: 5vh !important;
  border-radius: 35px !important;
  font-weight: 700 !important;
  font-size: 1.8vh !important;
  line-height: 3.5vh !important;
}

.jin--btn-primary {
  background-color: $orange !important;
  color: #fff !important;
}

.jin--btn-secondary {
  color: $orange !important;
  background-color: #fff !important;
  border: 1px solid $orange !important;
}

.jin--custom-header {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0.5vh;
  min-height: 4vw;
  padding-left: 3vh;
  padding-right: 3vh;
}

.jin--btn-custom-header {
  width: 15vw !important;
  margin-top: 2vh;
}

.jin--font {
  font-family: "Montserrat" !important;
}

.jin--table {
  margin: 1vh;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: $lightGrey !important;
  font-family: "Montserrat" !important;
  text-align: center !important;
  color: $orange !important;
  font-weight: 700;
  font-size: 1.2vw !important;
}

.ant-table-tbody > tr > td {
  font-family: "Montserrat" !important;
  text-align: center !important;
  color: $grey !important;
  font-weight: 700;
  font-size: 1vw !important;
}

.jin--svg {
  height: 2vh !important;
  width: 2vh !important;
  cursor: pointer;
}
.jin--modal {
  .ant-modal-header {
    min-height: 3vh;
    border-bottom: 1px solid #cacaca;
    text-align: center;
  }
  .ant-modal-title {
    color: #ed6a2f;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-modal-content {
    min-height: 20vh !important;
    border-radius: 28px !important;
    box-shadow: 0px 12px 27px -4px rgba(0, 0, 0, 0.25);
  }
}
.ant-upload-list-item-name{
    color: $orangeLight !important;
}
.jin--add-container {
  background-color: $lighterGrey;
  min-height: 20vh;
  margin: 2vh !important;
  border-radius: 2vh !important;
}

.jin--form-add {
  margin-top: 4vh !important;
  width: 100% !important;
}
.ant-form-item-required {
  font-family: "Montserrat" !important;
  color: $orange !important;
  font-weight: 700 !important;
  font-size: 0.8vw !important;
}
.ant-form-item .ant-form-item-label >label{
  font-family: "Montserrat" !important;
  color: $orange !important;
  font-weight: 700 !important;
  font-size: 0.8vw !important;
}
.ant-form-item{
  font-family: "Montserrat" !important;
  color: $orange !important;
  font-weight: 700 !important;
  font-size: 0.8vw !important;
}
.ant-switch.ant-switch-checked {
  background: $orange !important;
}
.ant-switch.ant-switch-unchecked {
  background: $switchOffColor !important;
}
.jin--switch {
  min-width: 55px !important;
  height: 22px !important;
  &.ant-switch-handle {
    width: 5px !important;
    height: 5px !important;
  }
}
.jin--tab-container {
  min-height: 8vh;

  width: 100%;
  align-items: center !important;
  display: flex;
  text-align: center;
  border-bottom: 1px solid $switchOffColor;
}
.jin--tab-item {
  font-weight: 700;
  color: $orange;
  line-height: 4vh;
  font-size: 1.4vw !important;
}

.jin--tab-active {
  background: $activeItem !important;
  border-radius: 1vh;
  cursor: pointer;
}
.jin--tab {
  cursor: pointer;
}
.jin--select {
  .ant-select-selector {
    border-radius: 35px !important;
    border: 3px solid $orangeLight !important;
    font-family: "Montserrat";
    min-height: 5vh !important;
    color: $orange !important;
    height: 100%;
  }
  .ant-select-selection-item {
    color: $orange !important;
  }

  .ant-select-dropdown .ant-select-item {
    color: $orange !important;
  }

  .ant-picker .ant-picker-input > input {
    color: $orange !important;
  }
}
.ant-descriptions .ant-descriptions-item-label{  font-family: "Montserrat" !important;
  color: $grey !important;
  font-weight: 700 !important;
  font-size: 0.8vw !important;
  }

  .ant-descriptions .ant-descriptions-item-content{
    font-family: "Montserrat" !important;
  color: $orange !important;
  font-weight: 700;
  font-size: 1vw !important;
  }
  .ant-table-row {
    cursor: pointer;
  }
.jin--card-accueil{
  background-color: #F1F1F1 !important;
  border-radius: 29px !important;
  
  &.ant-card .ant-card-head{
    min-height: 2vw !important;
  }
  & .ant-card-head-title{
    padding-top: 10px !important;
    font-family: "Montserrat" !important;
  font-weight: 700 !important;
  font-size: 1.2vh !important;
  color: $grey !important;
  word-wrap: break-word !important;
  }
}
.jin--card-nb{
  color: #ED6A2F;
text-align: center;
font-family: 'Montserrat';
font-size: 5vh;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.jin--card-subelement{
  color: #ED6A2F;
  font-family: 'Montserrat';
  font-size: 1.5vh;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}
.custom-tooltip{
  background-color: #f7f1ed;
  padding: 1vh;
  border-radius: 5px;
  min-width: 150px;
  z-index: 10000;
}

.jin--header-img{
  max-width: 7vw;
}
.jin--img-apropos{
  max-width: 200px;
}
.jin--apropos-text{
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 1vw;
  color: $grey;
}